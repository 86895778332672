import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44')
];

export const server_loads = [0,2,3,6];

export const dictionary = {
		"/": [~10],
		"/auth/error": [~36,[9]],
		"/auth/otp": [~37,[9]],
		"/auth/reset-password": [38,[9]],
		"/auth/reset-password/success": [39,[9]],
		"/auth/signin": [40,[9]],
		"/auth/signup": [~41,[9]],
		"/auth/signup/success": [42,[9]],
		"/auth/update-password": [~43,[9]],
		"/auth/update-password/success": [44,[9]],
		"/(app)/invitations/[invitationId]": [~11,[2]],
		"/(app)/organizations": [~12,[2]],
		"/(app)/organizations/create": [~35,[2]],
		"/(app)/organizations/[organizationId]": [~13,[2,3]],
		"/(app)/organizations/[organizationId]/assistant/[[sessionId]]": [~14,[2,3]],
		"/(app)/organizations/[organizationId]/dossier": [~15,[2,3,4]],
		"/(app)/organizations/[organizationId]/dossier/business-context": [~16,[2,3,4]],
		"/(app)/organizations/[organizationId]/dossier/chunks/[chunkId]": [~17,[2,3,4]],
		"/(app)/organizations/[organizationId]/dossier/data-sources": [~18,[2,3,4]],
		"/(app)/organizations/[organizationId]/dossier/iro-management": [~19,[2,3,4,5]],
		"/(app)/organizations/[organizationId]/dossier/iro-management/iro/[iroId]": [~20,[2,3,4,5]],
		"/(app)/organizations/[organizationId]/dossier/iro-management/topic/[topicId]": [~21,[2,3,4,5]],
		"/(app)/organizations/[organizationId]/projects": [~22,[2,3]],
		"/(app)/organizations/[organizationId]/projects/create": [~29,[2,3]],
		"/(app)/organizations/[organizationId]/projects/[projectId]": [~23,[2,3,6]],
		"/(app)/organizations/[organizationId]/projects/[projectId]/disclosures": [~24,[2,3,6]],
		"/(app)/organizations/[organizationId]/projects/[projectId]/disclosures/standard/[standardId]": [~25,[2,3,6]],
		"/(app)/organizations/[organizationId]/projects/[projectId]/materiality": [~26,[2,3,6,7]],
		"/(app)/organizations/[organizationId]/projects/[projectId]/materiality/matrix": [~27,[2,3,6,7]],
		"/(app)/organizations/[organizationId]/projects/[projectId]/materiality/table": [~28,[2,3,6,7]],
		"/(app)/organizations/[organizationId]/settings": [~30,[2,3,8]],
		"/(app)/organizations/[organizationId]/settings/data-access": [~31,[2,3,8]],
		"/(app)/organizations/[organizationId]/settings/general": [32,[2,3,8]],
		"/(app)/organizations/[organizationId]/settings/members": [~33,[2,3,8]],
		"/(app)/organizations/[organizationId]/settings/quota": [~34,[2,3,8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';